import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UrlMakerService from 're-client/services/url-maker'
import ky from 'ky'
import { cloneDeep } from 'lodash'

/**
 * Represents a lesson
 *
 * @class Lesson
 */
export class SampleLessonRouteModel {
  /**
   * List of activity objects for this lesson
   */
  activities: { lesson: SampleLessonRouteModel; id: number; json: unknown }[]

  id: string

  hideBackButton: boolean

  constructor({
    id,
    activities,
    hideBackButton,
  }: {
    id: string
    activities: unknown[]
    hideBackButton?: boolean
  }) {
    this.id = id
    this.activities = activities.map((json, index) => ({
      lesson: this,
      id: index + 1,
      json,
    }))
    this.hideBackButton = !!hideBackButton
  }

  /**
   * Find the next activity in the sequence, after the specified index
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the current activity index (1-based index)
   * @return {Object} A clone of the next activity object
   */
  nextActivity(activityIndex: number) {
    return cloneDeep(this.activities[activityIndex + 1])
  }

  /**
   * Find an activity by its index (1-based)
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the index (1-based index) of the activity to find
   * @return {Object} A clone of the activity object at the specified index
   */
  findActivity(activityIndex: number) {
    return cloneDeep(this.activities[activityIndex - 1])
  }

  /**
   * Find an activity manifest by its index (1-based)
   *
   * @method function
   * @memberOf Lesson
   * @param {Number} activityIndex - the index (1-based index) of the activity to find
   * @return {Object} A clone of the activity object's manifest at the specified index
   */
  findActivityManifest(activityIndex: number) {
    return cloneDeep(this.activities[activityIndex - 1]?.json)
  }
}

interface RouteParams {
  lesson_id: string
  isIframe?: string
}

/**
 * The sample lesson route
 */
export default class SampleLessonRoute extends Route<
  SampleLessonRouteModel,
  RouteParams
> {
  @service
  declare urlMaker: UrlMakerService

  override queryParams = {
    isIframe: {
      refreshModel: true,
    },
  }

  /**
   * Creates a lesson object and returns the first activity
   *
   * @param {String} id - The lesson id
   * @param {Array} activities - An array of lesson activities
   * @return {Object} - An activity object
   */
  getLessonActivity(id: string, activities: unknown[]) {
    const lesson: { activities: unknown[] } = new SampleLessonRouteModel({
      id,
      activities,
    })
    const [firstActivity] = lesson.activities
    return firstActivity
  }

  /**
   * Returns the first activity object of a lesson as the model
   *
   * @param params - Url params
   * @return Activity model
   */
  override async model(params: RouteParams) {
    const id = params.lesson_id
    const json = await ky
      .get(
        this.urlMaker.urlForInteractive('sample-lessons/reading/lessons', id),
      )
      .json<{ activities: unknown[] }>()

    return new SampleLessonRouteModel({
      id: params.lesson_id,
      activities: json.activities,
      hideBackButton: params.isIframe === 'true',
    })
  }

  override error(): void {
    window.history.back()
  }
}
