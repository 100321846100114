import Service, { inject as service } from '@ember/service'
import { assert, debug } from '@ember/debug'
import { tracked } from '@glimmer/tracking'
import type { FeatureService } from '@blakeelearning/features'
import type Log from '@blakeelearning/log/services/log'
import { useQuery } from 're-client/resources/query'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'
import type SessionService from 're-client/services/session'
import type Owner from '@ember/owner'
import type RouterService from '@ember/routing/router-service'
import { createBackendProvider } from '@blakeelearning/features'
import { useFragment } from 're-client/resources/fragment'
import type QuestService from './quest'

const _StudentUserFragment = graphql(/* GraphQL */ `
  fragment StudentUserFragment on Student {
    id
    avatarUrl
    eggs
    firstName
    lastName
    gamesAccess
    playroomAccess
    name @client
    remoteId @client
    dna {
      arms
      decal
      egg
      extra
      hat
      head
      legs
      sunnies
    }
    dnaHash @client
    featureFlags {
      name
      type
      value
    }
    locale
    backgroundMusicDisabled
    rosterEnabled
  }
`)

const StudentLocaleFragment = graphql(/* GraphQL */ `
  fragment StudentLocaleFragment on Student {
    locale
  }
`)

const StudentQueryDocument = graphql(/* GraphQL */ `
  query GetStudent {
    student {
      ...StudentUserFragment
      ...StudentLocaleFragment
      ...StudentQuestDataFragment
    }
  }
`)

export type GqlStudent = DocumentType<typeof _StudentUserFragment>

const guestStudentId = 'guest-student'

/**
 * Loads and stores user information (student model) for the user of the app.
 */
export default class UserService extends Service {
  @service
  declare session: SessionService

  @service
  declare log: Log

  @service
  declare features: FeatureService

  @service
  declare router: RouterService

  @service
  declare quest: QuestService

  _studentQuery = useQuery(this, () => {
    if (this.session.state.status === 'authenticated') {
      return {
        query: StudentQueryDocument,
      }
    }

    return {
      query: StudentQueryDocument,
      fetchPolicy: 'cache-only',
    }
  })

  _localeFragment = useFragment(this, () => ({
    fragment: StudentLocaleFragment,
    from: {
      __typename: 'Student',
      id: this._studentQuery.current.data?.student?.id,
    },
  }))

  removeSessionChangeHandler = () => {}

  get student() {
    const student = this._studentQuery.current.data?.student

    if (!student) {
      this.log.log('Session state', this.session.state)
      throw new Error('student is unset')
    }

    return student
  }

  /**
   * mysteryGiftAvailable flag is set from the quiz activity to enable and disable the
   * gifting feature.
   */
  @tracked mysteryGiftAvailable = false

  @tracked racingGameAvailable = false

  get locale() {
    return this.accent
  }

  @tracked isGuest = false

  get accent(): string {
    const accent = this._localeFragment.current?.locale
    const isCanadaAware = this.features.isEnabled('canada_aware')
    if (accent === 'ca' && !isCanadaAware) {
      return 'us'
    }
    return accent ?? 'au'
  }

  constructor(owner: Owner) {
    super(owner)

    this.removeSessionChangeHandler = this.session.onSessionChange(
      (session) => {
        if (this.isGuest) {
          return
        }

        const student = this._studentQuery.current.data?.student
        if (session.status === 'authenticated' && student) {
          const userId = session.data.user_id
          if (String(userId) !== student.id) {
            this.reset()
          }
        } else if (session.status === 'unauthenticated') {
          void this.router.replaceWith('unauthorised')
        }
      },
    )
  }

  /**
   * Setup the user service with the cached student data
   */
  async setup() {
    await this._studentQuery.current.result()

    this.log.setPerson({ id: this.student.id })

    await this.features.setup({
      logger: this.log,
      provider: createBackendProvider(this.student.featureFlags),
    })
  }

  /**
   * Method to set the mystery gift functionality available
   */
  setMysteryGiftAvailability(isAvailable = false) {
    assert('You must pass a boolean', typeof isAvailable === 'boolean')
    this.mysteryGiftAvailable = isAvailable
    return this.mysteryGiftAvailable
  }

  setRacingGameAvailability(isAvailable = false) {
    assert('You must pass a boolean', typeof isAvailable === 'boolean')
    this.racingGameAvailable = isAvailable
    return this.racingGameAvailable
  }

  /**
   * Setup a guest student - used for sample lessons.
   */
  asGuest() {
    this.isGuest = true
    this.log.setPerson({ id: guestStudentId })
    this._studentQuery.current.updateQuery(() => ({
      __typename: 'Query',
      student: {
        __typename: 'Student',
        id: guestStudentId,
        avatarUrl:
          'https://avatars.static.readingeggs.com/re/1-1-1-1-1-1-1-1.png',
        eggs: 0,
        firstName: 'Guest',
        lastName: 'Student',
        name: 'Guest S.',
        remoteId: guestStudentId,
        get dnaHash() {
          return JSON.stringify(this.dna)
        },
        locale: 'au',
        dna: {
          __typename: 'AvatarDna',
          arms: 'default_arms_1',
          decal: 'decal_1',
          egg: 'default_egg_1',
          extra: 'extra_1',
          hat: 'hat_1',
          head: 'default_head_1',
          legs: 'default_legs_1',
          sunnies: 'sunnies_1',
        },
        gamesAccess: true,
        playroomAccess: true,
        featureFlags: [],
        backgroundMusicDisabled: false,
        quest: {
          __typename: 'Quest',
          easyGoal: null,
          primaryGoal: null,
        },
        rosterEnabled: false,
      },
    }))

    return this._studentQuery.current.result()
  }

  /**
   * Fetch the student query
   */
  async fetch(): Promise<void> {
    await this._studentQuery.current.refetch()
    await this.setup()
  }

  /**
   * Increments the egg count on the student stored in the apollo cache
   */
  incrementEggs(amount: number) {
    debug(`incrementEggs() updating apollo cache`)
    this.quest.incrementEggGoals(amount)
    this._studentQuery.current.updateQuery((data) => {
      if (!data.student) return data

      return {
        __typename: 'Query',
        student: {
          ...data.student,
          eggs: data.student.eggs + amount,
        },
      }
    })
  }

  reset(location = window.location) {
    location.replace('/')
  }

  override willDestroy(): void {
    this.removeSessionChangeHandler()
  }
}

declare module '@ember/service' {
  interface Registry {
    user: UserService
  }
}
