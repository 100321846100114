import type { Log } from '@blakeelearning/log'
import type Operations from '@blakeelearning/student-operations/operations/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import type SpellingLessonActivityRoute from 're-client/routes/spelling/lesson/activity'
import type ActivitySidebarService from 're-client/services/activity-sidebar'
import type AssignmentsService from 're-client/services/assignments'
import type DebugModeService from 're-client/services/debug-mode'
import type LogoutService from 're-client/services/logout'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import camelizeKeys from 're-client/utils/camelize-keys'
import { debugAction } from 're-client/utils/debug'
import { calculateMap } from 're-client/utils/progress-tools'
import type { ModelFor } from 're-client/utils/route-model'

interface QuizResults {
  correct: number
  total: number
  correctAnswers: string[]
  incorrectAnswers: Record<string, string>
}

/**
 * The spelling activity controller
 *
 * @class SpellingController
 */
export default class SpellingLessonActivityController extends Controller {
  @service
  declare operations: Operations

  @service
  declare log: Log

  @service
  declare studentProgress: StudentProgressService

  @service
  declare assignments: AssignmentsService

  @service
  declare debugMode: DebugModeService

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare activitySidebar: ActivitySidebarService

  @service
  declare logout: LogoutService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  declare model: ModelFor<SpellingLessonActivityRoute>

  get sideBarActivityData() {
    return this.model.sideBarActivityData
  }

  get isAssignmentMode() {
    return this.model.isAssignment
  }

  get currentPositionTitle() {
    if (this.isAssignmentMode) return this.lessonId

    const currentLesson = this.studentProgress.spellingCurrentLesson
    if (currentLesson === 'quiz') {
      return 'Quiz'
    }
    return currentLesson
  }

  get lessonId() {
    return Number(this.model.lesson.id)
  }

  get activityId() {
    return Number(this.model.activityId)
  }

  get nextActivity() {
    const activityIndex = this.activityId - 1
    return this.model.lesson.nextActivity(activityIndex)
  }

  get studentContext() {
    let taskId
    let assignmentUuid

    if (
      this.assignments.canCompleteSpellingAssignmentTask(
        this.lessonId,
        this.activityId,
      )
    ) {
      taskId = this.assignments.currentTask?.id
      assignmentUuid = this.assignments.currentTask?.assignmentUuid
    }

    return {
      product: config.APP.product,
      precinct: 'spelling',
      remoteId: this.user.student.remoteId,
      taskId,
      assignmentUuid,
    }
  }

  get mapId() {
    const { lessonsPerMap } = config.studentProgress.progress.spelling
    return calculateMap(this.lessonId, lessonsPerMap)
  }

  get shouldLogout() {
    return (
      this.user.student.rosterEnabled &&
      this.isAssignmentMode &&
      !this.assignments.currentTask
    )
  }

  @action
  changeActivity(newActivityID: string | number) {
    this.router.transitionTo('spelling.lesson.activity', newActivityID)
  }

  @action
  @debugAction()
  next() {
    if (this.shouldLogout) {
      this.logout.doLogout()
      return
    }
    if (this.nextActivity) {
      this.router.transitionTo('spelling.lesson.activity', this.nextActivity.id)
    } else {
      this.goToMap()
    }
  }

  /**
   * Increment score maps to a external controller function (of the game component) which adds a given amount of eggs
   * to the student model. To make sure we match 100% with the backend reload the student aside from adding
   * the eggs in a delayed fashion to not interfere with the egg animation.
   * Incrementing the property manually feels more responsive in the UI which is
   * the reason to keep it.
   */
  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    if (typeof args === 'number') {
      this.user.incrementEggs(args)
    } else {
      this.activitySidebar.open(args.amount)
    }
  }

  /**
   * Save spelling lesson activity progress or assignment activity progress
   */
  @action
  @debugAction()
  async saveProgress() {
    let response

    try {
      response = await this.operations.completeActivity(
        this.studentContext,
        this.lessonId,
        this.activityId,
      )

      await this.studentProgress.fetchProgress()
      await this.assignments.fetch()
    } catch (error) {
      this.log.error(error as Error)
    }

    const data = {
      reward: { eggs: 0 },
      ...(camelizeKeys(response) as Record<string, unknown>),
    }
    this.interactive.callInteractionMethod('nextable', data)
  }

  /**
   * Save quizResults sends a `completeSpellingLessonQuiz` event to student_events
   * Based on the response we reward the student with a Mystery item from the shop
   * when they have successfully finished a lesson quiz for the first time.
   */
  @action
  @debugAction()
  async saveQuizResult(
    correct: number,
    total: number,
    incorrectAnswers: string[] | Record<string, string>,
    correctAnswers: string[],
  ) {
    let response

    try {
      response = await this.operations.completeSpellingLessonQuiz({
        context: this.studentContext,
        lesson: this.lessonId,
        // @ts-expect-error is this correct?
        activity: this.activityId,
        correct,
        total,
        incorrectAnswers,
        correctAnswers,
      })
      await this.studentProgress.fetchProgress()
      await this.assignments.fetch()

      const earnedShopReward = (response as { reward?: { shop?: boolean } })
        .reward?.shop
      const passedQuiz = (response as { passed?: boolean }).passed

      if (passedQuiz && earnedShopReward)
        this.user.setMysteryGiftAvailability(true)
    } catch (error) {
      this.log.error(error as Error)
    }

    const data = { reward: { eggs: 0 }, ...(response as object) }
    this.interactive.callInteractionMethod('nextable', data)
  }

  @action
  @debugAction()
  async completeActivity() {
    await this.saveProgress()
    this.next()
  }

  @action
  @debugAction()
  collectMysteryGift() {
    this.router.transitionTo('spelling.mystery-gift')
  }

  @action
  goToMap() {
    this.router.transitionTo('spelling.map', this.mapId)
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: 'Pass Quiz', value: 'pass' },
        { label: 'Fail Quiz', value: 'fail' },
      ],
      values: {
        pass: {
          correct: 12,
          total: 12,
          correctAnswers: [
            'debug1',
            'debug2',
            'debug3',
            'debug4',
            'debug5',
            'debug6',
            'debug7',
            'debug8',
            'debug9',
            'debug10',
            'debug11',
            'debug12',
          ],
          incorrectAnswers: {},
        },
        fail: {
          correct: 0,
          total: 12,
          correctAnswers: [],
          incorrectAnswers: {
            debug1: 'wrong 1',
            debug2: 'wrong 2',
            debug3: 'wrong 3',
            debug4: 'wrong 4',
            debug5: 'wrong 5',
            debug6: 'wrong 6',
            debug7: 'wrong 7',
            debug8: 'wrong 8',
            debug9: 'wrong 9',
            debug10: 'wrong 10',
            debug11: 'wrong 11',
            debug12: 'wrong 12',
          },
        },
      },
    },
  })
  async completeQuiz({ results }: { results: QuizResults }) {
    await this.saveQuizResult(
      results.correct,
      results.total,
      results.incorrectAnswers,
      results.correctAnswers,
    )

    this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'spelling/lesson/activity': SpellingLessonActivityController
  }
}
