import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type StudentProgressService from 're-client/services/student-progress'
import type AssignmentsService from 're-client/services/assignments'
import type RouterService from '@ember/routing/router-service'

interface RouteParams {
  type: string
  quiz_id: string
}

export default class DrivingTestsQuizRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare assignments: AssignmentsService

  @service
  declare router: RouterService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  override async model({ type, quiz_id: quizId }: RouteParams) {
    const isAssignmentMode =
      this.assignments.canCompleteDrivingTestAssignmentTask(
        parseInt(quizId),
        type,
      )

    if (
      this.studentProgress.isAreaLocked('drivingTests') &&
      !isAssignmentMode
    ) {
      void this.router.replaceWith('index')
      return
    }
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive(`driving-test-quizzes/${type}`, quizId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      isAssignmentMode,
      interactiveConfig,
    }
  }
}
