import Service, { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { v4 as uuidV4 } from 'uuid'
import type { FeatureService } from '@blakeelearning/features'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type ActivityService from 're-client/services/activity'
import { saveReadingLessonActivityResultMutationDocument } from 're-client/controllers/lessons/lesson/activity'
import config from 're-client/config/environment'
import type UrlMakerService from 're-client/services/url-maker'
import ky from 'ky'
import type UserService from './user'
import { useMutation } from 're-client/resources/mutation'
import { graphql } from 're-client/graphql'

const {
  APP: { product },
} = config

export const resetReadingLessonProgressMutationDocument = graphql(
  /* GraphQL */ `
    mutation ResetReadingLessonProgress(
      $input: StudentProgressReadingLessonResetInput!
    ) {
      studentProgressReadingLessonReset(input: $input) {
        student {
          id
          ...StudentProgressFragment
        }
      }
    }
  `,
)

export const resetReadingMapQuizProgressMutationDocument = graphql(
  /* GraphQL */ `
    mutation ResetReadingMapQuizProgress(
      $input: StudentProgressReadingMapQuizResetInput!
    ) {
      studentProgressReadingMapQuizReset(input: $input) {
        student {
          id
          ...StudentProgressFragment
        }
      }
    }
  `,
)

export const resetReadingPlacementTestProgressMutationDocument = graphql(
  /* GraphQL */ `
    mutation ResetReadingPlacementTestProgress {
      studentProgressReadingPlacementTestReset {
        student {
          id
          ...StudentProgressFragment
        }
      }
    }
  `,
)

/**
 * Controls the global state of debug mode.
 */
export default class DebugModeService extends Service {
  @service
  declare activity: ActivityService

  @service
  declare features: FeatureService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare operations: Operations

  @service
  declare user: UserService

  saveReadingLessonActivityResultMutation = useMutation(
    this,
    saveReadingLessonActivityResultMutationDocument,
  )

  resetReadingLessonProgressMutation = useMutation(
    this,
    resetReadingLessonProgressMutationDocument,
  )

  resetReadingMapQuizProgressMutation = useMutation(
    this,
    resetReadingMapQuizProgressMutationDocument,
  )

  resetReadingPlacementTestProgressMutation = useMutation(
    this,
    resetReadingPlacementTestProgressMutationDocument,
  )

  /**
   * Is debug mode enabled?
   */
  @tracked enabled = false

  /**
   * Enable debug mode:
   *  - if debugging is allowed in this environment
   *  - if the debug controls are visible
   */
  enable() {
    if (this.debugAllowed) {
      this.enabled = true
    }
  }

  /**
   * Disable debug mode
   */
  disable() {
    this.enabled = false
  }

  get debugAllowed() {
    return this.features.isEnabled('debug_allowed')
  }

  async completeLessonsLesson(lessonId: string) {
    if (this.enabled) {
      const { activityInLesson } =
        this.activity.lastReadingActivityForLesson(lessonId)

      await this.saveReadingLessonActivityResultMutation.current.mutate({
        variables: {
          input: {
            assignment: null,
            lessonInPrecinct: Number(lessonId),
            activityInLesson,
            attemptUuid: uuidV4(),
          },
        },
      })
    }
  }

  async completeSpellingLesson(lessonId: string) {
    if (this.enabled) {
      const precinct = 'spelling'
      const url = this.urlMaker.urlForInteractive('spelling/lessons', lessonId)
      const json = await ky.get(url).json<{ activities: unknown[] }>()
      const lastActivityNumber = json.activities.length

      const correctAnswers = [
        'debug1',
        'debug2',
        'debug3',
        'debug4',
        'debug5',
        'debug6',
        'debug7',
        'debug8',
        'debug9',
        'debug10',
        'debug11',
        'debug12',
      ]

      const { remoteId } = this.user.student

      await this.operations.completeSpellingLessonQuiz({
        context: {
          product,
          precinct,
          remoteId,
        },
        // @ts-expect-error unsure if this is needed?
        lesson: lessonId,
        activity: lastActivityNumber,
        correct: 12,
        total: 12,
        incorrectAnswers: {},
        correctAnswers,
      })
    }
  }

  async completeStorylandsLesson(lessonId: string) {
    if (this.enabled) {
      const precinct = 'clinker_castle'
      const url = this.urlMaker.urlForInteractive(
        'clinker-castle/lessons',
        lessonId,
      )
      const json = await ky.get(url).json<{ activities: unknown[] }>()
      const lastActivityNumber = json.activities.length

      const correctAnswers = [
        'debug1',
        'debug2',
        'debug3',
        'debug4',
        'debug5',
        'debug6',
        'debug7',
        'debug8',
        'debug9',
        'debug10',
      ]

      const { remoteId } = this.user.student

      await this.operations.completeLessonQuiz({
        context: {
          product,
          precinct,
          remoteId,
        },
        // @ts-expect-error unsure if this is needed?
        lesson: lessonId,
        activity: lastActivityNumber,
        correct: 10,
        total: 10,
        incorrectAnswers: {},
        correctAnswers,
      })
    }
  }

  async resetReadingLessonProgress(lesson: number, activity: number) {
    if (this.enabled) {
      await this.resetReadingLessonProgressMutation.current.mutate({
        variables: {
          input: {
            lesson,
            activity,
          },
        },
      })
    }
  }

  async resetReadingMapQuizProgress(map: number) {
    if (this.enabled) {
      await this.resetReadingMapQuizProgressMutation.current.mutate({
        variables: {
          input: {
            map,
          },
        },
      })
    }
  }

  async resetReadingPlacementTestProgress() {
    if (this.enabled) {
      await this.resetReadingPlacementTestProgressMutation.current.mutate({})
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'debug-mode': DebugModeService
  }
}
