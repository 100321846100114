import Service, { inject as service } from '@ember/service'
import { action } from '@ember/object'
import type OfflineScreenService from 're-client/services/offline-screen'
import type DeviceNetwork from '@blakeelearning/device/device/network/service'
import type SessionService from 're-client/services/session'

export default class LogoutService extends Service {
  @service
  declare offlineScreen: OfflineScreenService

  @service('device/network')
  declare network: DeviceNetwork

  @service
  declare session: SessionService

  @action
  doLogout() {
    if (this.network.status.isOffline) {
      this.offlineScreen.display()
      return
    }

    void this.session.signOut()
  }
}

declare module '@ember/service' {
  interface Registry {
    logout: LogoutService
  }
}
